<div class="table-box">
  <div class="table-actions">
    <div style="scale: 0.5" aria-hidden="true" *ngIf="loadingResources">
      <div class="card flex justify-content-center">
        <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="2" animationDuration=".5s" />
      </div>
    </div>
    <div *ngIf="!loadingResources" style="width: 50%; display: flex; align-items: center; justify-content: left">
      <div style="width: fit-content; margin-left: 8px">{{ dataService.getTexts().TableHeaderActions }}:</div>
      <div>
        <p-button
          icon="pi pi-play"
          [text]="true"
          class="control-button"
          *ngIf="isStartDisabled()"
          [disabled]="true"
          severity="contrast"
        />
        <p-button
          icon="pi pi-play"
          [text]="true"
          class="control-button-aktiv"
          *ngIf="!isStartDisabled()"
          (click)="startSelectedVms()"
          severity="contrast"
        />
      </div>
      <div>
        <p-button icon="pi pi-stop" [text]="true" class="control-button" *ngIf="isStopDisabled()" [disabled]="true" />
        <p-button
          icon="pi pi-stop"
          [text]="true"
          class="control-button-aktiv"
          *ngIf="!isStopDisabled()"
          (click)="stopSelectedVms()"
          severity="danger"
        />
      </div>
      <div>
        <p-button
          icon="pi pi-refresh"
          [text]="true"
          class="control-button"
          *ngIf="isRestartDisabled()"
          [disabled]="true"
        />
        <p-button
          icon="pi pi-refresh"
          [text]="true"
          class="control-button-aktiv"
          *ngIf="!isRestartDisabled()"
          (click)="restartSelectedVms()"
          severity="info"
        />
      </div>
      <div style="margin-top: 4px; margin-bottom: 4px">
        <p-divider layout="vertical" />
      </div>
      <div *ngIf="isAdminContext" style="margin-left: 12px">
        <p-button
          [label]="dataService.getTexts().DepartmentDetailAddButtonText"
          icon="pi pi-plus"
          (click)="addResource()"
        />
      </div>
    </div>
    <div style="width: 50%; display: flex; align-items: center; justify-content: right">
      <div>
        <p-button
          icon="pi pi-desktop"
          [text]="true"
          class="control-button"
          *ngIf="!isOnlyOneSelected()"
          [disabled]="true"
        />
        <p-button
          icon="pi pi-desktop"
          [text]="true"
          class="control-button-aktiv"
          *ngIf="isOnlyOneSelected()"
          (click)="connectToSelectedVm()"
        />
      </div>
      <div *ngIf="isAdminContext">
        <p-button
          icon="pi pi-trash"
          [text]="true"
          class="control-button"
          *ngIf="!isAtLeastOneSelected()"
          [disabled]="true"
        />
        <p-button
          icon="pi pi-trash"
          [text]="true"
          class="control-button-aktiv"
          *ngIf="isAtLeastOneSelected()"
          (click)="deleteSelectedVms()"
        />
      </div>
      <div class="ressource-search">
        <p-iconField iconPosition="left" style="margin-top: auto; margin-bottom: auto">
          <p-inputIcon>
            <i class="pi pi-search"></i>
          </p-inputIcon>
          <input
            pInputText
            type="text"
            [(ngModel)]="searchValue"
            (input)="dt.filterGlobal($event.target.value, 'contains')"
            [placeholder]="dataService.getTexts().SearchPlaceholder"
          />
        </p-iconField>
      </div>
      <div>
        <p-button
          icon="pi pi-sync"
          [text]="true"
          class="control-button-aktiv"
          (click)="relaodStatusOfAllVms()"
          [disabled]="resourcesOfDepartment.length === 0"
        />
      </div>
    </div>
  </div>
  <div>
    <p-table
      #dt
      [value]="resourcesOfDepartment"
      [(selection)]="selectedResources"
      dataKey="name"
      [tableStyle]="{ 'min-width': '50rem' }"
      [globalFilterFields]="['name']"
      styleClass="p-datatable-gridlines"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 4rem"><p-tableHeaderCheckbox /></th>
          <th>{{ dataService.getTexts().TableHeaderName }}</th>
          <th>{{ dataService.getTexts().TableHeaderType }}</th>
          <th>{{ dataService.getTexts().TableHeaderStatus }}</th>
          <th>{{ dataService.getTexts().TableHeaderAlerts }}</th>
          <th *ngIf="isAdminContext" style="width: 5rem"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product let-expanded="expanded">
        <tr>
          <td>
            <p-tableCheckbox [value]="product" />
          </td>
          <td>{{ product.name }}</td>
          <td>{{ product.type }}</td>

          <td>
            <div *ngIf="getStatus(product.id) === 'unknown'">
              <p-progressSpinner [styleClass]="'custom-progress-spinner'" animationDuration="1s"></p-progressSpinner>
            </div>
            <div *ngIf="getStatus(product.id) !== 'unknown'">
              {{ getStatus(product.id) }}
            </div>
          </td>
          <td>
            <div class="column-container">
              <div *ngIf="getStatus(product.id).includes('unknown')">/</div>
              <div *ngIf="!getStatus(product.id).includes('unknown')">
                <div *ngIf="getStatus(product.id) === 'VM stopping'">
                  {{ dataService.getTexts().VmAlertsStopping }}
                </div>
                <div *ngIf="getStatus(product.id).includes('VM deallocating')">
                  {{ dataService.getTexts().VmAlertsDeallocating }}
                </div>
                <div *ngIf="getStatus(product.id).includes('VM starting')">
                  {{ dataService.getTexts().VmAlertsStarting }}
                </div>
                <div *ngIf="getStatus(product.id).includes('VM stopped')">
                  {{ dataService.getTexts().VmAlertsStopped }}
                </div>
                <div
                  *ngIf="
                    getStatus(product.id).includes('VM deallocated') || getStatus(product.id).includes('VM running')
                  "
                >
                  /
                </div>
              </div>
            </div>
          </td>
          <td *ngIf="isAdminContext">
            <p-button
              [disabled]="getStatus(product.id) === 'unknown'"
              type="button"
              [label]="dataService.getTexts().VmMetainformationMetainformation"
              pRipple
              [pRowToggler]="product"
              [text]="true"
              [plain]="true"
              [icon]="expanded ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"
              style="margin-right: 8px"
            />
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-product>
        <tr>
          <td colspan="7">
            <div class="p-3">
              <div class="example-element-detail">
                <div style="width: 100%; height: auto">
                  <div style="width: auto; margin-left: 32px; margin-right: 32px" *ngIf="infoMap.size > 0">
                    <div class="horizontal-card-spacer"></div>
                    <p-card class="info-card">
                      <div class="info-container">
                        <div class="info-headline">{{ dataService.getTexts().VmMetainformationVm }}</div>
                        <p-divider layout="vertical" />
                        <div class="info-info">
                          <div class="info-line">
                            <div class="info-line-headline">
                              {{ dataService.getTexts().VmMetainformationStatus }}
                            </div>
                            <div class="info-line-content">
                              {{ getstatusText(product.id) }}
                            </div>
                          </div>
                          <div class="info-line">
                            <div class="info-line-headline">
                              {{ dataService.getTexts().VmMetainformationAdminUsername }}
                            </div>
                            <div class="info-line-content">
                              {{ getInfo(product.id).adminName }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </p-card>
                    <div class="horizontal-card-spacer"></div>
                    <p-card class="info-card"
                      ><div class="info-container">
                        <div class="info-headline">{{ dataService.getTexts().VmMetainformationHardware }}</div>

                        <p-divider layout="vertical" />
                        <div class="info-info">
                          <div class="info-line">
                            <div class="info-line-headline">
                              {{ dataService.getTexts().VmMetainformationVCpuCores }}
                            </div>
                            <div class="info-line-content">
                              {{ getInfo(product.id).cpuCores }}
                            </div>
                          </div>
                          <div class="info-line">
                            <div class="info-line-headline">
                              {{ dataService.getTexts().VmMetainformationRam }}
                            </div>
                            <div class="info-line-content">
                              {{ getInfo(product.id).ram }}
                            </div>
                          </div>
                        </div>
                        <p-divider layout="vertical" />
                        <div class="info-info">
                          <div class="info-line">
                            <div class="info-line-headline">
                              {{ dataService.getTexts().VmMetainformationDataDiskSize }}
                            </div>
                            <div class="info-line-content">
                              {{ getInfo(product.id).dataDiskSize }}
                            </div>
                          </div>
                          <div class="info-line">
                            <div class="info-line-headline">
                              {{ dataService.getTexts().VmMetainformationDataDisks }}
                            </div>
                            <div class="info-line-content">
                              {{ getInfo(product.id).dataDisks }}
                            </div>
                          </div>
                        </div>
                        <p-divider layout="vertical" />
                        <div class="info-info">
                          <div class="info-line">
                            <div class="info-line-headline">
                              {{ dataService.getTexts().VmMetainformationOsDiskSize }}
                            </div>
                            <div class="info-line-content">
                              {{ getInfo(product.id).osDiskSize }}
                            </div>
                          </div>
                          <div class="info-line">
                            <div class="info-line-headline">
                              {{ dataService.getTexts().VmMetainformationVmSize }}
                            </div>
                            <div class="info-line-content">
                              {{ getInfo(product.id).vmSize }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </p-card>
                    <div class="horizontal-card-spacer"></div>
                    <p-card class="info-card"
                      ><div class="info-container">
                        <div class="info-headline">{{ dataService.getTexts().VmMetainformationSoftware }}</div>
                        <p-divider layout="vertical" />
                        <div class="info-info">
                          <div class="info-line">
                            <div class="info-line-headline">
                              {{ dataService.getTexts().VmMetainformationOperatingSystem }}
                            </div>
                            <div class="info-line-content">
                              {{ getInfo(product.id).osName }}
                            </div>
                          </div>
                          <div class="info-line">
                            <div class="info-line-headline">
                              {{ dataService.getTexts().VmMetainformationOsVersion }}
                            </div>
                            <div class="info-line-content">
                              {{ getInfo(product.id).osVersion }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </p-card>
                    <div class="horizontal-card-spacer"></div>
                    <p-card class="info-card">
                      <div class="info-container">
                        <div class="info-headline">{{ dataService.getTexts().VmMetainformationAzure }}</div>
                        <p-divider layout="vertical" />
                        <div class="info-info">
                          <div class="info-line">
                            <div class="info-line-headline">
                              {{ dataService.getTexts().VmMetainformationRegion }}
                            </div>
                            <div class="info-line-content">
                              {{ getInfo(product.id).location }}
                            </div>
                          </div>
                          <div class="info-line">
                            <div class="info-line-headline">
                              {{ dataService.getTexts().VmMetainformationResourceGroup }}
                            </div>
                            <div class="info-line-content">
                              {{ getInfo(product.id).resourceGroupName }}
                              <i
                                class="pi pi-external-link"
                                style="font-size: 1rem; cursor: pointer; margin-left: 4px; margin-top: 2px"
                                (click)="goToLink(getInfo(product.id).resourceGroupUrl)"
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </p-card>
                    <div class="horizontal-card-spacer"></div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
