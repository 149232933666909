<div *ngIf="isConfigLoaded">
  <app-shell> </app-shell>
</div>

<div style="height: 90vh; display: flex; align-items: center; justify-content: center" *ngIf="!isConfigLoaded">
  <div style="display: flex; flex-direction: column; align-items: center">
    <div class="spinner icon-spinner-5" aria-hidden="true"></div>
    <div style="margin-top: 8px" class="shimmer">Please wait while we are processing your request...</div>
  </div>
</div>
