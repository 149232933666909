import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private readonly CONFIG_STORAGE_KEY = 'userConfig';
  private readonly SECRET_KEY = 'e1af9758-273e-4cef-9d59-feb649ceb37a';

  constructor() {}

  getConfig(): any {
    const storedConfig = localStorage.getItem(this.CONFIG_STORAGE_KEY);
    if (storedConfig) {
      const decryptedConfig = this.decrypt(storedConfig);
      return JSON.parse(decryptedConfig);
    }
    return null;
  }

  setConfig(config: any): void {
    const encryptedConfig = this.encrypt(JSON.stringify(config));
    localStorage.setItem(this.CONFIG_STORAGE_KEY, encryptedConfig);
  }

  hasConfig(): boolean {
    return localStorage.getItem(this.CONFIG_STORAGE_KEY) !== null;
  }

  isConfigDifferent(newConfig: any): boolean {
    const currentConfig = this.getConfig();
    return JSON.stringify(currentConfig) !== JSON.stringify(newConfig);
  }

  private encrypt(data: string): string {
    return CryptoJS.AES.encrypt(data, this.SECRET_KEY).toString();
  }

  private decrypt(data: string): string {
    return CryptoJS.AES.decrypt(data, this.SECRET_KEY).toString(CryptoJS.enc.Utf8);
  }
}
