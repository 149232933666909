import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  handleError(error: any): void {
    console.log(error);
  }
}
