export const SCOPES = {
  USER_INVITE_ALL: 'https://graph.microsoft.com/User.Invite.All',
  USER_IMPERSONATION: 'https://management.azure.com/user_impersonation',
  DIRECTORY_READ_ALL: 'Directory.Read.All',
  DIRECTORY_READ_WRITE_ALL: 'Directory.ReadWrite.All',
  APPLICATION_READ_ALL: 'Application.Read.All',
  APPLICATION_READ_WRITE_ALL: 'Application.ReadWrite.All',
  GRAPH_USER_READ: 'https://graph.microsoft.com/user.read',
  GROUPMEMBER_READ_ALL: 'GroupMember.Read.All',
  USER_READ_ALL: 'User.Read.All',
  AUDITLOG_READ_ALL: 'AuditLog.Read.All',
  USERAUTHENTICATION_READ_WRITE_ALL: 'UserAuthenticationMethod.ReadWrite.All',
  ROLEMANAGEMENT_READ_WRITE_DIRECTORY: 'https://graph.microsoft.com/RoleManagement.ReadWrite.Directory',
} as const;
