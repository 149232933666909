import { Component, OnInit } from '@angular/core';
import { ApiService } from 'app/features/admin/services/api-service/api.service';
import { DataService } from '../../services/data/data.service';
import { Config } from '../../models/config';
import { ConfigService } from './config.service';
import { SESSION_STORAGE_KEYS } from 'app/core/session_storage/constants/session-storage-keys.constant';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss'],
})
export class ConfigurationComponent implements OnInit {
  isConfigLoaded: boolean = false;

  constructor(
    private apiService: ApiService,
    private dataService: DataService,
    private configService: ConfigService,
  ) {}

  ngOnInit(): void {
    const localConfig = this.configService.getConfig();

    // Check if the user is in the onboarding process => onboarding active is set in the session storage
    const isCurrentlyOnboarding: string = sessionStorage.getItem(SESSION_STORAGE_KEYS.ONBOARDING_ACTIVE);

    if (isCurrentlyOnboarding !== null) {
      // We are in the onboarding process, so there is no config to load
      return;
    }

    if (localConfig) {
      this.loadConfig(localConfig);
    }

    this.apiService.getUserConfig().subscribe({
      next: (result: Config) => {
        if (this.configService.isConfigDifferent(result)) {
          this.configService.setConfig(result);
          this.loadConfig(result);
        }
      },
    });
  }

  private loadConfig(config: Config): void {
    this.dataService.setLoadUserGroups(config.isDepartmentsDynamic);
    this.dataService.setIsCustomRoleEnabled(config.isCustomRoleEnabled);
    this.dataService.setUserPortalDepartmentNameSingular(config.userPortalDepartmentNameSingular);
    this.dataService.setUserPortalDepartmentNamePlural(config.userPortalDepartmentNamePlural);
    this.dataService.setUserPortalUserNameSingular(config.userPortalUserNameSingular);
    this.dataService.setUserPortalUserNamePlural(config.userPortalUserNamePlural);
    this.dataService.setAdminPortalDepartmentNameSingular(config.adminPortalDepartmentNameSingular);
    this.dataService.setAdminPortalDepartmentNamePlural(config.adminPortalDepartmentNamePlural);
    this.dataService.setAdminPortalUserNameSingular(config.adminPortalUserNameSingular);
    this.dataService.setAdminPortalUserNamePlural(config.adminPortalUserNamePlural);
    this.dataService.setEmailSubjectLine(config.emailSubjectLine);
    this.dataService.setIsCronJobsEnabled(config.isCronJobsEnabled);
    this.dataService.setIsDepartmentsDynamic(config.isDepartmentsDynamic);
    this.dataService.setIsResourcesEnabled(config.isResourcesEnabled);
    this.dataService.setIsAdminPortalCommunityTileEnabled(config.isAdminPortalCommunityTileEnabled);
    this.dataService.setIsUserPortalCommunityTileEnabled(config.isUserPortalCommunityTileEnabled);
    this.dataService.setIsMonitoringEnabled(config.isMonitoringEnabled);
    this.dataService.setLanguage(config.languageAbbreviation);
    this.dataService.setLearningCardConfigs(config.learningMaterialCardsConfigs);
    this.dataService.setExternCardConfigs(config.externCardsConfigs);
    this.dataService.setFavoriteCardConfigs(config.favoriteCardsConfigs);
    this.dataService.setIsPrivateGptEnabled(config.isPrivateGptEnabled);
    this.dataService.setLicenseKey(config.licenseKey);
    this.dataService.setLicenseEdition(config.licenseEdition);
    this.dataService.setIsUserInviteEmailNotificationEnabled(config.isUserEmailNotificationOnInviteEnabled);
    this.dataService.setIsAdminInviteEmailNotificationEnabled(config.isAdminEmailNotificationOnInviteEnabled);
    this.dataService.setIsUserPortalEnabled(config.isUserPortalEnabled);
    this.dataService.setLightTheme(config.lightTheme);
    this.dataService.setDarkTheme(config.darkTheme);
    this.isConfigLoaded = true;
  }
}
