import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { GlobalErrorHandler } from 'app/GlobalErrorHandler';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NgModule } from '@angular/core';
import { AuthService } from './shared/services/auth.service';
import { SharedModule } from './shared/shared.module';
import { MsalConfigModule } from './core/auth/msal-config.module';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { QuillModule } from 'ngx-quill';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { StepperModule } from 'primeng/stepper';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    FormsModule,
    BrowserAnimationsModule,
    ProgressSpinnerModule,
    MsalConfigModule.forRoot(),
    QuillModule.forRoot(),
    RadioButtonModule,
    ButtonModule,
    DividerModule,
    StepperModule,
    ToastModule,
  ],
  providers: [
    AuthService,
    MessageService,
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
    {
      provide: GlobalErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
