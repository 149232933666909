import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable, catchError, firstValueFrom, retry, shareReplay, throwError } from 'rxjs';
import { ResourceDto } from '../models/resourceDTO';
import { VmPowerStatusDTO } from '@shared/models/vmPowerStatusDTO';

@Injectable({
  providedIn: 'root',
})
export class ResourceApiService {
  private baseUrl = environment.baseUrl;
  private resourceController = 'management/resources';

  constructor(private httpClient: HttpClient) {}

  async getResourcesOfDepartment(departmentId: string): Promise<ResourceDto[]> {
    const response = this.httpClient
      .get<ResourceDto[]>(this.baseUrl + this.resourceController + '/department/' + departmentId)
      .pipe(retry(1), catchError(this.formatErrors))
      .pipe(shareReplay(1));
    return firstValueFrom(response) as Promise<ResourceDto[]>;
  }

  getVmPowerStatus(resourceIds: string[]): Observable<VmPowerStatusDTO[]> {
    return this.httpClient.put<VmPowerStatusDTO[]>(
      this.baseUrl + this.resourceController + '/vms/powerstatus',
      resourceIds,
    );
  }

  private formatErrors(error: any): Observable<never> {
    return throwError(() => new Error(error.error));
  }
}
