<div class="add-resource-frame">
  <!-- Body start -->
  <p-stepper [linear]="true">
    <p-stepperPanel [header]="this.dataService.getTexts().AddResourceSelectTenantStepTitle" class="add-resource-body">
      <ng-template pTemplate="content" let-nextCallback="nextCallback" let-index="index">
        <form [formGroup]="selectTenantForm">
          <p-messages
            [value]="[{ severity: 'info', detail: this.dataService.getTexts().AddResourceSelectTenantInfoText }]"
            [enableService]="false"
            [closable]="false"
            [showTransitionOptions]="'0ms'"
          />

          <p-dataView #dv [value]="diference">
            <ng-template let-products pTemplate="listItem">
              <div *ngFor="let product of diference; let i = index; let first = first">
                <div [ngClass]="{ 'border-top-1 surface-border': !first }"></div>
                <div class="selection-list-entries" (click)="selectedTenant(product, nextCallback)">
                  <div>{{ product.displayName }}</div>
                  <div style="margin-right: 16px">
                    <i class="pi pi-chevron-right"></i>
                  </div>
                </div>
              </div>
            </ng-template>
          </p-dataView>
        </form>
      </ng-template>
    </p-stepperPanel>

    <p-stepperPanel [header]="this.dataService.getTexts().AddResourceSelectSubscriptionStepTitle">
      <ng-template
        pTemplate="content"
        let-prevCallback="prevCallback"
        let-nextCallback="nextCallback"
        let-index="index"
      >
        <p-messages
          [value]="[{ severity: 'info', detail: this.dataService.getTexts().AddResourceSelectSubscriptionInfoText }]"
          [enableService]="false"
          [closable]="false"
          [showTransitionOptions]="'0ms'"
        />

        <p-dataView #dv [value]="getSubscriptionsOfSelectedTenant()">
          <ng-template let-products pTemplate="listItem">
            <div *ngFor="let product of getSubscriptionsOfSelectedTenant(); let i = index; let first = first">
              <div [ngClass]="{ 'border-top-1 surface-border': !first }"></div>
              <div class="selection-list-entries" (click)="selectedSubscription(product, nextCallback)">
                <div>{{ product.displayName }}</div>
                <div style="margin-right: 16px">
                  <i class="pi pi-chevron-right"></i>
                </div>
              </div>
            </div>
          </ng-template>
        </p-dataView>
      </ng-template>
    </p-stepperPanel>

    <p-stepperPanel [header]="this.dataService.getTexts().AddResourceSelectResourceGroupStepTitle">
      <ng-template
        pTemplate="content"
        let-prevCallback="prevCallback"
        let-nextCallback="nextCallback"
        let-index="index"
      >
        <p-messages
          [value]="[{ severity: 'info', detail: this.dataService.getTexts().AddResourceSelectResourceGroupInfoText }]"
          [enableService]="false"
          [closable]="false"
          [showTransitionOptions]="'0ms'"
        />

        <p-dataView #dv [value]="resourceGroups">
          <ng-template let-products pTemplate="listItem">
            <div *ngFor="let product of resourceGroups; let i = index; let first = first">
              <div [ngClass]="{ 'border-top-1 surface-border': !first }"></div>
              <div class="selection-list-entries" (click)="selectedResourceGroup(product, nextCallback)">
                <div>{{ product.name }}</div>
                <div style="margin-right: 16px">
                  <i class="pi pi-chevron-right"></i>
                </div>
              </div>
            </div>
          </ng-template>
        </p-dataView>
      </ng-template>
    </p-stepperPanel>

    <p-stepperPanel [header]="this.dataService.getTexts().AddResourceSelectResourceStepTitle">
      <ng-template
        pTemplate="content"
        let-prevCallback="prevCallback"
        let-nextCallback="nextCallback"
        let-index="index"
      >
        <p-dataView #dv [value]="resources">
          <ng-template let-products pTemplate="listItem">
            <div *ngFor="let product of resources; let i = index; let first = first">
              <div [ngClass]="{ 'border-top-1 surface-border': !first }"></div>
              <div class="selection-list-entries" (click)="selectedResource(product, nextCallback)">
                <div>
                  <p>
                    {{ product.name }}
                  </p>
                  <p style="font-size: smaller">
                    {{ this.resourceType }}
                  </p>
                </div>
                <div style="margin-right: 16px">
                  <i class="pi pi-chevron-right"></i>
                </div>
              </div>
            </div>
          </ng-template>
        </p-dataView>
        <div style="margin-top: 16px">
          <p-button [label]="this.dataService.getTexts().ButtonTextBack" (onClick)="prevCallback.emit()" />
        </div>
      </ng-template>
    </p-stepperPanel>

    <p-stepperPanel [header]="this.dataService.getTexts().AddResourceSelectConfirmationStepTitle">
      <ng-template pTemplate="content" let-prevCallback="prevCallback" let-index="index">
        <div class="verify-box">
          <div class="verify-box-colums">
            <p>{{ this.dataService.getTexts().AddResourceConfirmationTenant }}:</p>
            <p>{{ this.dataService.getTexts().AddResourceConfirmationSubscription }}:</p>
            <p>{{ this.dataService.getTexts().AddResourceConfirmationResourceGroup }}:</p>
            <p>{{ this.dataService.getTexts().AddResourceConfirmationResource }}:</p>
          </div>

          <div class="verify-box-colums">
            <p>
              {{ tenantName }}
            </p>
            <p>
              {{ subscriptionName }}
            </p>
            <p>
              {{ rgName }}
            </p>
            <p>
              {{ resourceName }}
            </p>
          </div>

          <div class="verify-box-colums">
            <div>
              <i *ngIf="tenantName === ''" class="pi pi-times verify-icon-error"></i>
              <i *ngIf="tenantName !== ''" class="pi pi-check verify-icon-ok"></i>
            </div>
            <div>
              <i *ngIf="subscriptionName === ''" class="pi pi-times verify-icon-error"></i>
              <i *ngIf="subscriptionName !== ''" class="pi pi-check verify-icon-ok"></i>
            </div>
            <div>
              <i *ngIf="rgName === ''" class="pi pi-times verify-icon-error"></i>
              <i *ngIf="rgName !== ''" class="pi pi-check verify-icon-ok"></i>
            </div>
            <div>
              <i *ngIf="resourceName === ''" class="pi pi-times verify-icon-error"></i>
              <i *ngIf="resourceName !== ''" class="pi pi-check verify-icon-ok"></i>
            </div>
          </div>
        </div>
        <div style="height: 8px"></div>
        <p-button
          [label]="this.dataService.getTexts().AddResourceConfirmationAddResourceButtonText"
          icon="pi pi-plus"
          [disabled]="tenantName === '' || subscriptionName === '' || rgName === '' || resourceName === ''"
          (onClick)="addResource()"
        />
      </ng-template>
    </p-stepperPanel>
  </p-stepper>
  <!-- Body end -->
</div>
