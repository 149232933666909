import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom, Observable } from 'rxjs';
import { VmPowerStatusDTO } from '@shared/models/vmPowerStatusDTO';
import { ResourceDto } from 'app/core/api/models/resourceDTO';
import { AddResourceComponent } from '../../../features/admin/components/add-resource/add-resource.component';
import { FormControl } from '@angular/forms';
import { ApiService } from '../../../features/admin/services/api-service/api.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DeletePopUpComponent } from '../../../features/admin/components/delete-pop-up/delete-pop-up.component';
import { DataService } from '@shared/services/data/data.service';
import { ErrorService } from '@shared/services/error/error.service';
import { ResourceApiService } from 'app/core/api/services/resource-api.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DIALOG_CONFIG } from '@shared/constants/dialog';
import { IsAdminService } from '@shared/services/is-admin.service';
import { ToastService } from '@shared/services/toast/toast.service';

@Component({
  selector: 'app-resources-detail',
  templateUrl: './resources-detail.component.html',
  styleUrls: ['./resources-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ResourcesDetailComponent implements OnInit {
  isAdminContext: boolean = false;

  resourcesOfDepartment: ResourceDto[] = [];

  selectedResources: ResourceDto[] = [];

  expandedRows = {};

  searchValue: string = '';

  loadingResources: boolean = true;

  vmPowerStatus: Observable<VmPowerStatusDTO[]>;

  resourceMap = new Map();

  conStringMap = new Map<string, string>();

  infoMap = new Map<string, VmPowerStatusDTO>();

  isExtendedMap = new Map<string, boolean>();

  resourceColumns: string[] = ['Name', 'Type', 'Status', 'Alerts'];

  displayedResourceColumns: string[] = ['Select', ...this.resourceColumns];

  columnsToDisplayWithExpand = [...this.displayedResourceColumns, 'expand'];

  departmentId: string;

  position = new FormControl('below');

  dialogRef: DynamicDialogRef | undefined;

  constructor(
    private resourceApiService: ResourceApiService,
    private route: ActivatedRoute,
    private apiService: ApiService,
    public dataService: DataService,
    private errorService: ErrorService,
    public dialogService: DialogService,
    private isAdminService: IsAdminService,
    private toastService: ToastService,
  ) {}

  ngOnInit(): void {
    this.isAdminContext = this.isAdminService.getCurrentIsAdmin();

    this.isAdminService.isAdmin$.subscribe((status) => {
      this.isAdminContext = status;
    });

    this.route.params.subscribe((s) => {
      this.departmentId = s.id;
    });
    this.loadResourcesOfDepartment();
  }

  async loadResourcesOfDepartment(): Promise<void> {
    this.resourcesOfDepartment = await this.resourceApiService.getResourcesOfDepartment(this.departmentId);
    this.resourcesOfDepartment.sort((a, b) => a.type.localeCompare(b.type));

    if (this.resourcesOfDepartment.length > 0) {
      this.loadVmPowerStatus(this.resourcesOfDepartment);
    }

    this.loadingResources = false;
  }

  async loadVmPowerStatus(resources: ResourceDto[]): Promise<void> {
    let resourceIds: string[] = [];
    for (let i = 0; i < resources.length; i++) {
      if (resources[i].type == 'Virtual Machine') {
        if (this.resourceMap.get(resources[i].id) == undefined) {
          this.resourceMap.set(resources[i].id, 'unknown');
        }
        resourceIds.push(resources[i].id);
      }
    }
    this.resourceApiService.getVmPowerStatus(resourceIds).subscribe({
      next: (status: VmPowerStatusDTO[]) => {
        status.forEach((s: VmPowerStatusDTO) => this.deserializeStatus(s, true));
      },
      error: () => {
        resourceIds.forEach((element) => {
          this.resourceMap.set(element, 'Unavailable');
        });
      },
    });
  }

  deserializeStatus(statusDTO: VmPowerStatusDTO, setExtended: boolean): void {
    let date = new Date();
    let hours = date.getHours().toString();
    if (hours.length == 1) {
      hours = '0' + hours;
    }
    let minutes = date.getMinutes().toString();
    if (minutes.length == 1) {
      minutes = '0' + minutes;
    }
    let seconds = date.getSeconds().toString();
    if (seconds.length == 1) {
      seconds = '0' + seconds;
    }
    let time = hours + ':' + minutes + ':' + seconds;
    this.conStringMap.set(statusDTO.id, statusDTO.rdpConnectionString);
    this.resourceMap.set(statusDTO.id, time + ' ' + statusDTO.status);
    if (setExtended && this.isAdminContext) {
      this.isExtendedMap.set(statusDTO.id, false);
      this.infoMap.set(statusDTO.id, statusDTO);
    }
  }

  dowloadFile(resourceId: string): void {
    const conString = this.conStringMap.get(resourceId);
    if (conString == '') {
      this.toastService.showErrorMessage(this.dataService.getTexts().ToastVmRdpFileUnavailableTitle);
      return;
    }
    let strings = conString?.split('!');
    if (strings != undefined) {
      for (let j = 0; j < strings.length; j++) {
        var temp = strings[j];
        temp = temp.replace('!', '');
        strings[j] = temp;
      }
      const blob = new Blob([strings[0], '\n', strings[1], '\n', strings[2]], {
        type: 'rdp',
      });
      const url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = 'Vm Connection';
      for (let i = 0; i < this.resourcesOfDepartment.length; i++) {
        if (this.resourcesOfDepartment[i].id == resourceId) {
          a.download = this.resourcesOfDepartment[i].name + '.rdp';
          break;
        }
      }
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }
  }

  addResource(): void {
    this.dialogRef = this.dialogService.open(AddResourceComponent, {
      header: this.dataService.getTexts().AddResourceTitle,
      data: { resourcesOfDepartment: this.resourcesOfDepartment },
      width: '70%',
      height: '70%',
    });

    this.dialogRef.onClose.pipe().subscribe({
      next: (resourceObservable: Observable<ResourceDto>) => this.handleNewResourceObservable(resourceObservable),
      error: () => {
        this.toastService.showErrorMessage(this.dataService.getTexts().ToastResourceAddErrorTitle);
      },
    });
  }

  handleNewResourceObservable(resourceObs: Observable<ResourceDto>): void {
    if (resourceObs != undefined) {
      resourceObs.subscribe({
        next: (resource: ResourceDto) => this.handleNewResource(resource),
        error: () => {
          this.toastService.showErrorMessage(this.dataService.getTexts().ToastResourceAddErrorTitle);
        },
      });
    }
  }

  handleNewResource(resource: ResourceDto): void {
    if (resource != undefined) {
      this.resourcesOfDepartment.push(resource);
      this.resourcesOfDepartment = this.resourcesOfDepartment.sort((a, b) => a.name.localeCompare(b.name));
      this.toastService.showSuccessMessage(this.dataService.getTexts().ToastResourceAddSuccessTitle);
      this.loadVmPowerStatus([resource]);
    }
  }

  removeResource(resourceId: string): void {
    this.apiService.removeResourceFromDepartment(this.departmentId, resourceId).subscribe(() => {
      this.resourcesOfDepartment = this.resourcesOfDepartment
        .filter((resource) => resource.id != resourceId)
        .sort((a, b) => a.name.localeCompare(b.name));
    });
  }

  startSelectedVms(): void {
    let selected = this.selectedResources;
    selected.forEach((element) => {
      this.apiService.startVm(element.id, this.departmentId).subscribe({
        next: () => {},
        error: (error: any) => {
          this.errorService.handleError(error);
        },
      });
    });
    this.toastService.showSuccessMessage(this.dataService.getTexts().ToastVmStartingTitle);
    var ids = selected.map((element) => element.id);
    this.reloadStatus(ids, 'VM running');
  }

  stopSelectedVms(): void {
    let selected = this.selectedResources;
    selected.forEach((element) => {
      this.apiService.stopVm(element.id, this.departmentId).subscribe({
        next: () => {},
        error: (error: any) => {
          this.errorService.handleError(error);
        },
      });
    });
    this.toastService.showSuccessMessage(this.dataService.getTexts().ToastVmStoppingTitle);
    var ids = selected.map((element) => element.id);
    this.reloadStatus(ids, 'VM deallocated');
  }

  restartSelectedVms(): void {
    let selected = this.selectedResources;
    selected.forEach((element) => {
      this.apiService.restartVm(element.id, this.departmentId).subscribe({
        next: () => {},
        error: (error: any) => {
          this.errorService.handleError(error);
        },
      });
    });
    this.toastService.showSuccessMessage(this.dataService.getTexts().ToastVmRestartingTitle);
    var ids = selected.map((element) => element.id);
    this.reloadStatus(ids, 'VM running');
  }

  connectToSelectedVm(): void {
    let selected = this.selectedResources;
    selected.forEach((element) => {
      this.dowloadFile(element.id);
    });
  }

  deleteSelectedVms(): void {
    let selected = this.selectedResources;
    let deleteDialogRef = this.dialogService.open(DeletePopUpComponent, {
      header: `${this.dataService.getTexts().DeletePopUpTextStart} ${this.dataService.getTexts().DepartmentDetailResourcesTabTitle} (${selected.length}) ${this.dataService.getTexts().DeletePopUpTextEnd} `,
      data: {
        isDelete: true,
        isUpdate: false,
      },
      style: {
        height: DIALOG_CONFIG.height,
        width: DIALOG_CONFIG.width,
      },
    });
    deleteDialogRef.onClose.subscribe({
      next: (response: boolean) => {
        if (response != undefined) {
          if (response) {
            if (selected.length > 0) {
              selected.forEach((element) => {
                this.removeResource(element.id);
              });
            }
          }
        }
      },
    });
  }

  relaodStatusOfAllVms(): void {
    let resources = this.resourcesOfDepartment;
    for (let i = 0; i < resources.length; i++) {
      if (resources[i].type == 'Virtual Machine') {
        this.resourceMap.set(resources[i].id, 'unknown');
      }
    }

    let resourceIds = this.resourcesOfDepartment.map((resource: ResourceDto) => resource.id);
    this.resourceApiService.getVmPowerStatus(resourceIds).subscribe({
      next: (status: VmPowerStatusDTO[]) => {
        status.forEach((s: VmPowerStatusDTO) => this.deserializeStatus(s, true));
      },
      error: () => {
        resourceIds.forEach((element) => {
          this.resourceMap.set(element, 'Unavailable');
        });
      },
    });
  }

  async reloadStatus(resourceIds: string[], expectedStatus: string): Promise<void> {
    let counter = 0;
    while (counter < 30) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      counter++;
      let result = this.apiService.getVmPowerStatusUpdate(resourceIds);
      let response = await firstValueFrom(result);
      if (response.toString() != '') {
        response.forEach((status) => {
          this.deserializeStatus(status, false);
        });
        let stop = true;
        response.forEach((status) => {
          if (status.status != expectedStatus) {
            stop = false;
          }
        });
        if (stop) {
          counter = 30;
        }
      }
    }
  }

  isStartDisabled(): boolean {
    let selected = this.selectedResources;
    if (selected.length == 0) return true;
    let disabled = false;
    selected
      .map((res: ResourceDto) => this.resourceMap.get(res.id))
      .forEach((status: string) => {
        if (!status.includes('VM deallocated') && !status.includes('VM stopped')) {
          disabled = true;
        }
        if (status.includes('unknown') || status == 'Unavailable') {
          disabled = true;
        }
      });
    return disabled;
  }

  isStopDisabled(): boolean {
    let selected = this.selectedResources;
    if (selected.length == 0) return true;
    let disabled = false;
    selected
      .map((res: ResourceDto) => this.resourceMap.get(res.id))
      .forEach((status: string) => {
        if (!status.includes('VM running') && !status.includes('VM stopped')) {
          disabled = true;
        }
        if (status.includes('unknown') || status == 'Unavailable') {
          disabled = true;
        }
      });
    return disabled;
  }

  isRestartDisabled(): boolean {
    let selected = this.selectedResources;
    if (selected.length == 0) return true;
    let disabled = false;
    selected
      .map((res: ResourceDto) => this.resourceMap.get(res.id))
      .forEach((status: string) => {
        if (!status.includes('VM running')) {
          disabled = true;
        }
        if (status.includes('unknown') || status == 'Unavailable') {
          disabled = true;
        }
      });
    return disabled;
  }

  isAtLeastOneSelected(): boolean {
    let selected = this.selectedResources;
    if (selected.length > 0) return true;
    return false;
  }

  isOnlyOneSelected(): boolean {
    let selected = this.selectedResources;
    if (selected.length == 1) {
      let disabled = true;
      selected
        .map((res: ResourceDto) => this.resourceMap.get(res.id))
        .forEach((status: string) => {
          if (status.includes('unknown') || status == undefined || status == 'Unavailable') {
            disabled = false;
          }
        });
      return disabled;
    }
    return false;
  }

  getStatus(resourceId: string): string {
    return this.resourceMap.get(resourceId) ?? 'unknown';
  }

  getstatusText(resourceId: string): string {
    let status = this.getStatus(resourceId);
    return status.substring(9, status.length);
  }

  getInfo(resourceId: string): VmPowerStatusDTO {
    if (this.infoMap.size > 0) {
      let info = this.infoMap.get(resourceId);
      if (info != undefined) {
        return info;
      }
    }
  }

  showVmInfo(resourceId: string): void {
    this.isExtendedMap.set(resourceId, true);
  }

  hideVmInfo(resourceId: string): void {
    this.isExtendedMap.set(resourceId, false);
  }

  isElementExtended(resourceId: string): boolean {
    if (this.isExtendedMap.size > 0) {
      return this.isExtendedMap.get(resourceId);
    }
    return false;
  }

  goToLink(url: string): void {
    window.open(url, '_blank');
  }

  compare(a: string, b: string, isAsc: boolean): number {
    if (!isAsc) {
      return a.localeCompare(b);
    } else {
      return b.localeCompare(a);
    }
  }
}
