import { Injectable } from '@angular/core';
import { Scope } from '../types/scope.type';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { AuthenticationResult } from '@azure/msal-browser';
import { getMsalTenantId } from 'app/core/auth/msal.factory';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  constructor(private readonly msalService: MsalService) {}

  acquireToken(scopes: Scope[], authority?: string): Observable<AuthenticationResult> {
    return this.msalService.acquireTokenSilent({
      scopes: scopes,
      authority: authority ?? 'https://login.microsoftonline.com/' + getMsalTenantId() + '/',
      account: this.msalService.instance.getAllAccounts()[0],
    });
  }
}
