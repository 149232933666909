import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

interface ToastMessage {
  severity: string;
  summary: string;
  detail: string;
  key: string;
}

export const TOAST_KEY = 'centraltoast';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toastMessageObs$ = new BehaviorSubject<ToastMessage | null>(null);

  constructor() {}

  getToastMessageObs(): Observable<ToastMessage | null> {
    return this.toastMessageObs$.asObservable();
  }

  showInformationMessage(summary: string, detail: string | null = null): void {
    this.toastMessageObs$.next({ severity: 'info', summary, detail, key: TOAST_KEY });
  }

  showSuccessMessage(summary: string, detail: string | null = null): void {
    this.toastMessageObs$.next({ severity: 'success', summary, detail, key: TOAST_KEY });
  }

  showWarningMessage(summary: string, detail: string | null = null): void {
    this.toastMessageObs$.next({ severity: 'warning', summary, detail, key: TOAST_KEY });
  }

  showErrorMessage(summary: string, detail: string | null = null): void {
    this.toastMessageObs$.next({ severity: 'danger', summary, detail, key: TOAST_KEY });
  }
}
