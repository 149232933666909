import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { CreateTenantDTO } from '../models/createTenanatDTO';
import { ApiService } from './api-service/api.service';
import { environment } from '@environments/environment';
import { Tenant } from '../models/tenant';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  private baseUrl = environment.baseUrl;

  private tenantController = 'management/tenants';

  constructor(private apidata: ApiService, private http: HttpClient) {}

  getTenantList(): Observable<Tenant[]> {
    if (this.apidata.getAdminTenants() != null) {
      return this.apidata.getAdminTenants();
    } else {
      throwError(() => new Error('No Tenants List found'));
    }
  }

  getAdminTenantsList(): Observable<Tenant[]> {
    if (this.apidata.getAdminTenants() != null) {
      return this.apidata.getAdminTenants();
    } else {
      throwError(() => new Error('No Tenants List found'));
    }
  }

  createTenant(tenant: CreateTenantDTO): Observable<CreateTenantDTO> {
    return this.http
      .post<CreateTenantDTO>(this.baseUrl + this.tenantController, tenant, httpOptions)
      .pipe(retry(3), catchError(this.formatErrors));
  }

  private formatErrors(error: any): Observable<never> {
    return throwError(() => new Error(error.error));
  }
}
