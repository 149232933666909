import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { User } from '@shared/models/user';
import { AuthService } from '@shared/services/auth.service';
import { Observable } from 'rxjs';
import { UserApiService } from 'app/features/user/services/api-service/api.service.component';
import { CurrentUserDataDTO } from '../../models/currentUserDataDTO';
import { DataService } from '../../services/data/data.service';
import { SESSION_STORAGE_KEYS } from 'app/core/session_storage/constants/session-storage-keys.constant';
import { Router } from '@angular/router';
import { ThemeService } from '@shared/services/theme/theme.service';

@Component({
  selector: 'app-usermenu',
  templateUrl: './usermenu.component.html',
  styleUrls: ['./usermenu.component.scss'],
})
export class UsermenuComponent implements OnInit {
  @ViewChild('nameDiv') nameDiv: ElementRef;
  @ViewChild('emailDiv') emailDiv: ElementRef;

  checked: boolean = false;

  user: User;

  userData: Observable<CurrentUserDataDTO>;

  username = '';

  email = 'unknown';

  statusObservable: Observable<string>;

  avatar: boolean;

  pic: any;

  isLanguageSwitchEnabled: boolean = false;
  currentLanguage: string = undefined;
  isLanguageSelectionChecked: boolean = false;

  isLightTheme: boolean = true;

  constructor(
    private auth: AuthService,
    private apiService: UserApiService,
    private sanitizer: DomSanitizer,
    public dataService: DataService,
    public themeService: ThemeService,
    private router: Router,
  ) {
    this.statusObservable = this.auth.getStatus();
    this.statusObservable.subscribe((status) => {
      if (status == 'none') {
        this.loadUserData();
      }
    });
  }

  ngOnInit(): void {
    this.auth.getUser();
    this.getAadProfilePicture();

    this.dataService.getIsLanguageSwitchEnabled().subscribe((isLanguageSwitchEnabled: boolean) => {
      if (!this.isLanguageSwitchEnabled) {
        this.currentLanguage = this.dataService.getLanguage();
      }

      this.isLanguageSwitchEnabled = isLanguageSwitchEnabled;

      if (this.currentLanguage === 'en') {
        this.isLanguageSelectionChecked = true;
      } else {
        this.isLanguageSelectionChecked = false;
      }
    });

    this.dataService.getIsLightThemeObs().subscribe((isLightTheme: boolean) => {
      this.isLightTheme = isLightTheme;
    });
  }

  loadUserData(): void {
    this.apiService.getCurrentUserData().subscribe((data: CurrentUserDataDTO) => {
      this.username = data.name;
      this.email = data.email;
    });
  }

  getAadProfilePicture(): void {
    this.auth.getAadProfilePicture().subscribe({
      next: (image: any) => {
        if (image != null) {
          let objectURL = URL.createObjectURL(image);
          this.pic = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          this.avatar = true;
        } else {
          this.avatar = false;
        }
      },
      error: () => {
        this.avatar = false;
      },
    });
  }

  signOut(): void {
    localStorage.removeItem(SESSION_STORAGE_KEYS.TENANT_ID);
    this.auth.logout();
  }

  scaleUserMenu(): void {
    var nameElement = this.nameDiv.nativeElement;
    var size = parseInt(getComputedStyle(nameElement as Element).getPropertyValue('font-size'));
    const parent_width = 240;
    while (nameElement.offsetWidth > parent_width) {
      nameElement.style.fontSize = size + 'px';
      size -= 1;
    }
    if (size > 18) {
      nameElement.style.fontSize = 18 + 'px';
    }

    var emailElement = this.emailDiv.nativeElement;
    size = parseInt(getComputedStyle(nameElement as Element).getPropertyValue('font-size'));
    while (emailElement.offsetWidth > parent_width) {
      emailElement.style.fontSize = size + 'px';
      size -= 1;
    }
    if (size > 13) {
      emailElement.style.fontSize = 13 + 'px';
    }
  }

  getAvatarUrl(): string {
    return `https://ui-avatars.com/api/?name=${this.username}`;
  }
  onSwitchChange() {
    const currentUrl = this.router.url;
    if (currentUrl.startsWith('/admin')) {
      this.router.navigate(['/dashboard']);
    } else if (currentUrl.startsWith('/dashboard')) {
      this.router.navigate(['/admin']);
    }
  }

  onThemeChange(event: any): void {
    this.checked = event.checked;
    this.themeService.setLightTheme(this.checked);
  }

  onLanguageSwitchChange(event: any): void {
    this.isLanguageSelectionChecked = event.checked;

    if (event.checked) {
      localStorage.setItem(SESSION_STORAGE_KEYS.CLIENT_LANGUAGE, 'en');
      this.dataService.setLanguage('en');
    } else {
      localStorage.setItem(SESSION_STORAGE_KEYS.CLIENT_LANGUAGE, 'de');
      this.dataService.setLanguage('de');
    }
  }
}
