import { GetTenantDTO } from '../../models/getTenantDTO';
import { ResourceDto } from 'app/core/api/models/resourceDTO';
import { RessourceGroups } from '../../models/ressourceGroups';
import { Subscriptions } from '../../models/subscriptions';
import { Tenant } from '../../models/tenant';
import { AddRessources } from '../../services/AddRessource.service';
import { ApiService } from '../../services/api-service/api.service';
import { TenantService } from '../../services/tenant.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { DataService } from '@shared/services/data/data.service';
import { ActivatedRoute, RouterStateSnapshot } from '@angular/router';
import { ErrorService } from '@shared/services/error/error.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-add-resource',
  templateUrl: './add-resource.component.html',
  styleUrls: ['./add-resource.component.scss'],
})
export class AddResourceComponent implements OnInit {
  departmentId: string;

  tenant: Tenant[] = [];

  tennantId: string;

  tenantName: string = '';

  subscriptions: Subscriptions[] = [];

  resourceGroups: RessourceGroups[] = [];

  resources: ResourceDto[] = [];

  resourceName: string = '';

  resourceType: string;

  resourceToAdd: ResourceDto;

  subscriptionId: string;

  subscriptionIdGuid: Guid;

  subscriptionName: string = '';

  resourceGroup: RessourceGroups;

  rgName: string = '';

  subscriptionScope: string;

  id: string;

  adminTenant: Tenant[] = [];

  diference: Tenant[] = [];

  suppotedResource: boolean;

  resourcesOfDepartment: ResourceDto[] = [];

  selectTenantForm: UntypedFormGroup = new UntypedFormGroup({});

  selectSubscriptionForm: UntypedFormGroup = new UntypedFormGroup({});

  selectResourceGroupForm: UntypedFormGroup = new UntypedFormGroup({});

  selectResourceForm: UntypedFormGroup = new UntypedFormGroup({});

  constructor(
    private tenantService: AddRessources,
    private apidata: ApiService,
    private adminTenantList: TenantService,
    public dataService: DataService,
    private errorService: ErrorService,
    private route: ActivatedRoute,
    private dialogConfig: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
  ) {
    this.resourcesOfDepartment = this.dialogConfig.data.resourcesOfDepartment;
    this.selectTenantForm.addControl('tenant', new UntypedFormControl('', Validators.required));
    this.selectSubscriptionForm.addControl('subscription', new UntypedFormControl('', Validators.required));
    this.selectResourceGroupForm.addControl('resourceGroup', new UntypedFormControl('', Validators.required));
    this.selectResourceForm.addControl('resource', new UntypedFormControl('', Validators.required));
  }

  ngOnInit(): void {
    // FIXME This is a hacky workaround to get the id from the url.
    // Maybe we should use a different approach to get the id.
    const hacky: any = this.route.snapshot as any;
    const coercedType: RouterStateSnapshot = hacky._routerState as RouterStateSnapshot;
    const regexForId = /\/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}(\/|$)/;
    let extractedParam = coercedType.url.match(regexForId)[0].replace('/', '');

    this.departmentId = extractedParam;

    this.getTenants();
    this.getSubscriptions();
  }

  async getTenants(): Promise<void> {
    try {
      this.tenantService.getUserTenants().subscribe((response) => {
        this.tenant = response.value;

        this.adminTenantList.getAdminTenantsList().subscribe((adminResponse) => {
          this.adminTenant = adminResponse;
          this.getDifference(this.tenant, this.adminTenant);
        });
      });
    } catch (error: any) {
      this.errorService.handleError(error);
    }
  }

  getDifference(tenant: Tenant[], adminTenant: Tenant[]): void {
    const adminMap = new Map(adminTenant.map((t) => [t.tenantId, t]));
    this.diference = tenant.filter((t) => adminMap.has(t.tenantId));
  }

  async getSubscriptions(): Promise<void> {
    try {
      this.tenantService.getUserSubscriptions().subscribe((response) => {
        this.subscriptions = response.value;
      });
    } catch (error: any) {
      this.errorService.handleError(error);
    }
  }

  async getResourceGroups(): Promise<void> {
    try {
      this.tenantService.getUserResourceGroups(this.subscriptionId).subscribe((response) => {
        this.resourceGroups = response.value;
      });
    } catch (error: any) {
      this.errorService.handleError(error);
    }
  }

  async getResources(): Promise<void> {
    try {
      this.tenantService.getUserResources(this.subscriptionId, this.resourceGroup.name).subscribe((response) => {
        this.suppotedResource =
          response.value.filter((vm) => vm.type == 'Microsoft.Compute/virtualMachines').length > 0;

        if (this.suppotedResource) {
          this.resources = response.value.filter((vm) => vm.type == 'Microsoft.Compute/virtualMachines');
          var tempResources = this.resources;
          this.resources = [];
          for (var i = 0; i < tempResources.length; i++) {
            var contains = false;
            for (var j = 0; j < this.resourcesOfDepartment.length; j++) {
              if (tempResources[i].name == this.resourcesOfDepartment[j].name) {
                contains = true;
              }
            }
            if (!contains) {
              this.resources.push(tempResources[i]);
            }
          }
          response.value.forEach((vm) => {
            if (vm.type == 'Microsoft.Compute/virtualMachines') {
              this.resourceType = 'Virtual Machine';
            }
          });
        }
      });
    } catch (error: any) {
      this.errorService.handleError(error);
    }
  }

  async addSpToResource(): Promise<void> {
    this.apidata.getTenant(this.tennantId).subscribe({
      next: (tenant: GetTenantDTO) => {
        this.tenantService
          .addServicePrincipalToResource(
            this.subscriptionId,
            this.subscriptionScope,
            uuidv4(),
            tenant.clientId,
            tenant.id,
          )
          .subscribe((_response: any) => {});
      },
      error: (error: any) => {
        this.errorService.handleError(error);
      },
    });
  }

  selectedTenant(tenant: Tenant, nextCallback: any): void {
    this.tennantId = tenant.tenantId;
    this.tenantName = tenant.displayName;
    this.selectTenantForm.controls.tenant.setValue(this.tennantId);
    if (this.selectTenantForm.valid) {
      nextCallback.emit();
    }
  }

  getSubscriptionsOfSelectedTenant(): Subscriptions[] {
    return this.subscriptions.filter((s) => s.tenantId == this.tennantId);
  }

  selectedSubscription(subscription: Subscriptions, nextCallback: any): void {
    this.subscriptionId = subscription.subscriptionId;
    this.subscriptionName = subscription.displayName;
    this.getResourceGroups();
    this.selectSubscriptionForm.controls.subscription.setValue(this.subscriptionId);
    if (this.selectSubscriptionForm.valid) {
      nextCallback.emit();
    }
  }

  selectedResourceGroup(resourceGroup: RessourceGroups, nextCallback: any): void {
    this.rgName = resourceGroup.name;
    this.resourceGroup = resourceGroup;
    this.subscriptionScope = resourceGroup.id;

    // New resource group has been selected! Reset all previously fetched resources!
    this.resources = [];
    this.resourceName = '';

    // Get the resources of the newly selected resource group.
    this.getResources();

    this.selectResourceGroupForm.controls.resourceGroup.setValue(this.rgName);
    if (this.selectResourceGroupForm.valid) {
      nextCallback.emit();
    }
  }

  selectedResource(resource: ResourceDto, nextCallback: any): void {
    this.resourceToAdd = resource;
    this.resourceName = resource.name;
    this.selectResourceForm.controls.resource.setValue(this.resourceName);
    if (this.selectResourceForm.valid) {
      nextCallback.emit();
    }
    nextCallback.emit();
  }

  async addResource(): Promise<void> {
    this.dialogRef.close(await this.createResource(this.resourceToAdd));
  }

  async createResource(resource: ResourceDto): Promise<Observable<ResourceDto>> {
    await this.addSpToResource();
    return this.apidata.createResource(
      (this.resourceName = resource.name),
      (this.resourceType = resource.type),
      this.resourceGroup.name,
      this.tennantId,
      this.departmentId,
      this.subscriptionId,
    );
  }

  close(): void {
    this.dialogRef.close();
  }
}
