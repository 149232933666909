import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IsAdminService {
  // Initialisiere BehaviorSubject mit einem Default-Wert (false)
  private isAdminSubject = new BehaviorSubject<boolean>(null);

  // Exponiere das Observable für Abonnements
  public isAdmin$: Observable<boolean> = this.isAdminSubject.asObservable();

  constructor() {}

  // Methode zum Setzen des Admin-Status
  public setIsAdmin(isAdmin: boolean): void {
    this.isAdminSubject.next(isAdmin);
  }

  // Methode zum Abfragen des aktuellen Admin-Status
  public getCurrentIsAdmin(): boolean {
    return this.isAdminSubject.getValue();
  }
}
