import { Component } from '@angular/core';
import { DataService } from '@shared/services/data/data.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-delete-pop-up',
  templateUrl: './delete-pop-up.component.html',
  styleUrls: ['./delete-pop-up.component.scss'],
})
export class DeletePopUpComponent {
  visible: boolean = true;
  data: any;

  constructor(
    public dataService: DataService,
    private dialogConfig: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
  ) {
    this.data = this.dialogConfig.data;
  }

  getFirstLetterFromName(name: string): string {
    return name.charAt(0).toUpperCase();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  delete(): void {
    this.dialogRef.close(true);
  }
}
