import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { OnboardingComponent } from './features/admin/components/onboarding/onboarding.component';
import { TenantConsentComponent } from './features/admin/components/onboarding/tenant-consent/tenant-consent.component';

const routes: Routes = [
  {
    path: 'admin',
    canActivate: [MsalGuard],
    loadChildren: () => import('./features/admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'instances',
    canActivate: [MsalGuard],
    loadChildren: () => import('./features/instance-admin/instance-admin.module').then((m) => m.InstanceAdminModule),
  },
  {
    path: '',
    canActivate: [MsalGuard],
    loadChildren: () => import('./features/user/user.module').then((m) => m.UserModule),
  },
  {
    path: 'onboarding/:id',
    component: OnboardingComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'consent',
    component: TenantConsentComponent,
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
