import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ClipboardModule } from '@angular/cdk/clipboard';
import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MsalInterceptor } from '@azure/msal-angular';
import { ShellComponent } from './components/shell/shell.component';
import { UsermenuComponent } from './components/usermenu/usermenu.component';
import { ErrorInterceptorService } from './services/interceptors/error-interceptor.service';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { ResourcesDetailComponent } from './components/resources-detail/resources-detail.component';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { DividerModule } from 'primeng/divider';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ButtonModule } from 'primeng/button';
import { TenantSelectionComponent } from './components/tenant-selection/tenant-selection.component';
import { FormsModule } from '@angular/forms';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { ChipModule } from 'primeng/chip';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TableModule } from 'primeng/table';
import { CardModule } from 'primeng/card';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { SidebarModule } from 'primeng/sidebar';

const components = [
  ShellComponent,
  UsermenuComponent,
  ConfigurationComponent,
  ResourcesDetailComponent,
  TenantSelectionComponent,
];

const modules = [
  CommonModule,
  LayoutModule,
  HttpClientModule,
  ReactiveFormsModule,
  RouterModule,
  ClipboardModule,
  MenuModule,
  MenubarModule,
  DividerModule,
  RadioButtonModule,
  ButtonModule,
  FormsModule,
  OverlayPanelModule,
  AvatarModule,
  AvatarGroupModule,
  ChipModule,
  ProgressSpinnerModule,
  InputSwitchModule,
  TableModule,
  CardModule,
  IconFieldModule,
  InputIconModule,
  InputTextModule,
  DynamicDialogModule,
  SidebarModule,
];

@NgModule({
  declarations: [...components],
  imports: [...modules],
  exports: [...components, ...modules],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true,
    },
    DialogService,
  ],
})
export class SharedModule {}
