import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { BehaviorSubject } from 'rxjs';
import { DataService } from '../data/data.service';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private defaultThemeName: string = environment.defaultThemeName;

  private currentTheme: BehaviorSubject<string> = new BehaviorSubject<string>(this.getCurrentTheme());

  private isLightTheme: boolean = this.getIsLightTheme();

  private lightTheme: string | null = null;
  private darkTheme: string | null = null;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private dataService: DataService,
  ) {
    this.dataService.getLightThemeObs().subscribe({
      next: (lightTheme: string | null) => {
        if (lightTheme) {
          this.lightTheme = lightTheme;
          localStorage.setItem('light-theme', lightTheme);
          if (this.isLightTheme) {
            this.switchTheme(this.lightTheme);
          } else {
            this.switchTheme(this.darkTheme);
          }
        }
      },
    });

    this.dataService.getDarkThemeObs().subscribe({
      next: (darkTheme: string | null) => {
        if (darkTheme) {
          this.darkTheme = darkTheme;
          localStorage.setItem('dark-theme', darkTheme);
          if (this.isLightTheme) {
            this.switchTheme(this.lightTheme);
          } else {
            this.switchTheme(this.darkTheme);
          }
        }
      },
    });

    this.dataService.setIsLightTheme(this.isLightTheme);
  }

  private getIsLightTheme(): boolean {
    if (localStorage.getItem('is-light-theme') !== null) {
      return localStorage.getItem('is-light-theme') === 'true';
    }
    localStorage.setItem('is-light-theme', 'true');
    return true;
  }

  public setLightTheme(isLightTheme: boolean): void {
    this.isLightTheme = isLightTheme;
    localStorage.setItem('is-light-theme', isLightTheme ? 'true' : 'false');
    this.dataService.setIsLightTheme(this.isLightTheme);

    if (isLightTheme) {
      this.switchTheme(this.lightTheme);
    } else {
      this.switchTheme(this.darkTheme);
    }
  }

  public getDefaultThemeName(): string {
    return this.defaultThemeName;
  }

  public switchTheme(theme: string): void {
    let themeLink: HTMLLinkElement = this.document.getElementById('theme-link') as HTMLLinkElement;

    if (themeLink) {
      if (typeof theme !== 'undefined' && theme !== '') {
        themeLink.href = theme + '.css';
        localStorage.setItem('chosen-theme', theme);
      } else {
        themeLink.href = this.defaultThemeName + '.css';
        localStorage.setItem('chosen-theme', this.defaultThemeName);
      }
    }
    this.currentTheme.next(theme);
  }

  private getCurrentTheme(): string {
    return localStorage.getItem('chosen-theme') ?? this.defaultThemeName;
  }

  public getCurrentThemeObs(): BehaviorSubject<string> {
    return this.currentTheme;
  }
}
