import { Injectable } from '@angular/core';
import { Texts } from '../language/texts';
import { LanguageService } from '../language/language-service';
import { updateConfigRequestDTO } from '../../models/updateConfigRequestDTO';
import { DashBoardCardConfigDTO } from '@shared/models/dashboardCardConfigDTO';
import { PrimeNGConfig } from 'primeng/api';
import { SESSION_STORAGE_KEYS } from 'app/core/session_storage/constants/session-storage-keys.constant';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  //private currentTenant: string = '';

  //private currentTenantName: string = undefined;
  private isCustomRoleEnabled: boolean = true;

  private loadUserGroups: boolean = true;

  private userPortalDepartmentNameSingular: string = 'Department';

  private userPortalDepartmentNamePlural: string = 'Departments';

  private userPortalUserNameSingular: string = 'User';

  private userPortalUserNamePlural: string = 'Users';

  private adminPortalDepartmentNameSingular: string = 'Department';

  private adminPortalDepartmentNamePlural: string = 'Departments';

  private adminPortalUserNameSingular: string = 'User';

  private adminPortalUserNamePlural: string = 'Users';

  private emailSubjectLine: string = 'white duck GmbH - Cloud Control invitation';

  private isAdminPortalCommunityTileEnabled: boolean = false;

  private isCronJobsEnabled: boolean = false;

  private isDepartmentsDynamic: boolean = false;

  private isMonitoringEnabled: boolean = false;

  private isResourcesEnabled: boolean = false;

  private isUserPortalCommunityTileEnabled: boolean = false;

  private isPrivateGptEnabled: boolean = false;

  private language: string = 'de';

  private texts: Texts = {} as Texts;

  private userInviteEmailContent: string = '';

  private adminInviteEmailContent: string = '';

  private externCardConfigs: DashBoardCardConfigDTO[];

  private favoriteCardConfigs: DashBoardCardConfigDTO[];

  private learningCardConfigs: DashBoardCardConfigDTO[];

  private licenseKey: string = '';

  private licenseEdition: string = '';

  private isUserInviteEmailNotificationEnabled: boolean = false;

  private isAdminInviteEmailNotificationEnabled: boolean = false;

  private isUserPortalEnabled: boolean = true;

  private isLanguageSwitchEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private currentTenantName: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private lightTheme: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  private darkTheme: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  private isLightTheme: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private languageTextsService: LanguageService,
    private primengConfig: PrimeNGConfig,
  ) {}

  getTexts(): Texts {
    if (this.texts.AdminDashboardTitle == undefined) {
      this.texts = this.languageTextsService.getTexts(
        this.language,
        'Admin',
        'Admins',
        this.adminPortalUserNameSingular,
        this.adminPortalUserNamePlural,
        this.adminPortalDepartmentNameSingular,
        this.adminPortalDepartmentNamePlural,
      );
    }
    return this.texts;
  }

  getClientLangauge(): string | null {
    var clientLanguage = navigator.language || (navigator.languages && navigator.languages[0]) || null;
    return clientLanguage;
  }

  setLanguage(language: string): void {
    if (language === undefined || language === null) return;

    var clientLanguage = this.getClientLangauge();

    if (clientLanguage !== null && !clientLanguage.includes(language)) {
      var languageFormLocalStorage = localStorage.getItem(SESSION_STORAGE_KEYS.CLIENT_LANGUAGE);

      if (languageFormLocalStorage !== null) {
        language = languageFormLocalStorage;
      }

      this.isLanguageSwitchEnabled.next(true);
    }

    if (language !== this.language) {
      this.texts = {} as Texts;
      if (language === 'de') {
        this.language = 'de';
      } else if (language === 'en') {
        this.language = 'en';
      } else this.language = 'en';
    }

    this.getTexts();

    this.primengConfig.setTranslation({
      firstDayOfWeek: 1,
      monthNames: [
        this.texts.January,
        this.texts.February,
        this.texts.March,
        this.texts.April,
        this.texts.May,
        this.texts.June,
        this.texts.July,
        this.texts.August,
        this.texts.September,
        this.texts.October,
        this.texts.November,
        this.texts.December,
      ],
      monthNamesShort: [
        this.texts.PrimeNgMonthNamesShort[0],
        this.texts.PrimeNgMonthNamesShort[1],
        this.texts.PrimeNgMonthNamesShort[2],
        this.texts.PrimeNgMonthNamesShort[3],
        this.texts.PrimeNgMonthNamesShort[4],
        this.texts.PrimeNgMonthNamesShort[5],
        this.texts.PrimeNgMonthNamesShort[6],
        this.texts.PrimeNgMonthNamesShort[7],
        this.texts.PrimeNgMonthNamesShort[8],
        this.texts.PrimeNgMonthNamesShort[9],
        this.texts.PrimeNgMonthNamesShort[10],
        this.texts.PrimeNgMonthNamesShort[11],
      ],
      dayNames: [
        this.texts.PrimeNgDayNames[0],
        this.texts.PrimeNgDayNames[1],
        this.texts.PrimeNgDayNames[2],
        this.texts.PrimeNgDayNames[3],
        this.texts.PrimeNgDayNames[4],
        this.texts.PrimeNgDayNames[5],
        this.texts.PrimeNgDayNames[6],
      ],
      dayNamesShort: [
        this.texts.PrimeNgDayNamesShort[0],
        this.texts.PrimeNgDayNamesShort[1],
        this.texts.PrimeNgDayNamesShort[2],
        this.texts.PrimeNgDayNamesShort[3],
        this.texts.PrimeNgDayNamesShort[4],
        this.texts.PrimeNgDayNamesShort[5],
        this.texts.PrimeNgDayNamesShort[6],
      ],
      dayNamesMin: [
        this.texts.SundayMin,
        this.texts.MondayMin,
        this.texts.TuesdayMin,
        this.texts.WednesdayMin,
        this.texts.ThursdayMin,
        this.texts.FridayMin,
        this.texts.SaturdayMin,
      ],
      dateFormat: this.texts.PrimeNgDateFormat,
      today: this.texts.PrimeNgToday,
      weekHeader: this.texts.PrimeNgWeekHeader,
      weak: this.texts.PrimeNgWeak,
      medium: this.texts.PrimeNgMedium,
      strong: this.texts.PrimeNgStrong,
      passwordPrompt: this.texts.PrimeNgPasswordPrompt,
      emptyMessage: this.texts.PrimeNgEmptyMessage,
      emptyFilterMessage: this.texts.PrimeNgEmptyFilterMessage,
      pending: this.texts.PrimeNgPending,
      chooseYear: this.texts.PrimeNgChooseYear,
      chooseMonth: this.texts.PrimeNgChooseMonth,
      chooseDate: this.texts.PrimeNgChooseDate,
      prevDecade: this.texts.PrimeNgPrevDecade,
      nextDecade: this.texts.PrimeNgNextDecade,
      prevYear: this.texts.PrimeNgPrevYear,
      nextYear: this.texts.PrimeNgNextYear,
      prevMonth: this.texts.PrimeNgPrevMonth,
      nextMonth: this.texts.PrimeNgNextMonth,
      prevHour: this.texts.PrimeNgPrevHour,
      nextHour: this.texts.PrimeNgNextHour,
      prevMinute: this.texts.PrimeNgPrevMinute,
      nextMinute: this.texts.PrimeNgNextMinute,
      prevSecond: this.texts.PrimeNgPrevSecond,
      nextSecond: this.texts.PrimeNgNextSecond,
      am: this.texts.PrimeNgAm,
      pm: this.texts.PrimeNgPm,
      searchMessage: this.texts.PrimeNgSearchMessage,
      selectionMessage: this.texts.PrimeNgSelectionMessage,
      emptySelectionMessage: this.texts.PrimeNgEmptySelectionMessage,
      emptySearchMessage: this.texts.PrimeNgEmptySearchMessage,
      startsWith: this.texts.PrimeNgStartsWith,
      contains: this.texts.PrimeNgContains,
      notContains: this.texts.PrimeNgNotContains,
      endsWith: this.texts.PrimeNgEndsWith,
      equals: this.texts.PrimeNgEquals,
      notEquals: this.texts.PrimeNgNotEquals,
      noFilter: this.texts.PrimeNgNoFilter,
      lt: this.texts.PrimeNgLt,
      lte: this.texts.PrimeNgLte,
      gt: this.texts.PrimeNgGt,
      gte: this.texts.PrimeNgGte,
      is: this.texts.PrimeNgIs,
      isNot: this.texts.PrimeNgIsNot,
      before: this.texts.PrimeNgBefore,
      after: this.texts.PrimeNgAfter,
      dateIs: this.texts.PrimeNgDateIs,
      dateIsNot: this.texts.PrimeNgDateIsNot,
      dateBefore: this.texts.PrimeNgDateBefore,
      dateAfter: this.texts.PrimeNgDateAfter,
      clear: this.texts.PrimeNgClear,
      apply: this.texts.PrimeNgApply,
      matchAll: this.texts.PrimeNgMatchAll,
      matchAny: this.texts.PrimeNgMatchAny,
      addRule: this.texts.PrimeNgAddRule,
      removeRule: this.texts.PrimeNgRemoveRule,
      accept: this.texts.PrimeNgAccept,
      reject: this.texts.PrimeNgReject,
      choose: this.texts.PrimeNgChoose,
      upload: this.texts.PrimeNgUpload,
      cancel: this.texts.PrimeNgCancel,
      fileSizeTypes: this.texts.PrimeNgFileSizeTypes,
      aria: {
        trueLabel: this.texts.PrimeNgAria.trueLabel,
        falseLabel: this.texts.PrimeNgAria.falseLabel,
        nullLabel: this.texts.PrimeNgAria.nullLabel,
        star: this.texts.PrimeNgAria.star,
        stars: this.texts.PrimeNgAria.stars,
        selectAll: this.texts.PrimeNgAria.selectAll,
        unselectAll: this.texts.PrimeNgAria.unselectAll,
        close: this.texts.PrimeNgAria.close,
        previous: this.texts.PrimeNgAria.previous,
        next: this.texts.PrimeNgAria.next,
        navigation: this.texts.PrimeNgAria.navigation,
        scrollTop: this.texts.PrimeNgAria.scrollTop,
        moveTop: this.texts.PrimeNgAria.moveTop,
        moveUp: this.texts.PrimeNgAria.moveUp,
        moveDown: this.texts.PrimeNgAria.moveDown,
        moveBottom: this.texts.PrimeNgAria.moveBottom,
        moveToTarget: this.texts.PrimeNgAria.moveToTarget,
        moveToSource: this.texts.PrimeNgAria.moveToSource,
        moveAllToTarget: this.texts.PrimeNgAria.moveAllToTarget,
        moveAllToSource: this.texts.PrimeNgAria.moveAllToSource,
        pageLabel: this.texts.PrimeNgAria.pageLabel,
        firstPageLabel: this.texts.PrimeNgAria.firstPageLabel,
        lastPageLabel: this.texts.PrimeNgAria.lastPageLabel,
        nextPageLabel: this.texts.PrimeNgAria.nextPageLabel,
        prevPageLabel: this.texts.PrimeNgAria.prevPageLabel,
        rowsPerPageLabel: this.texts.PrimeNgAria.rowsPerPageLabel,
        previousPageLabel: this.texts.PrimeNgAria.previousPageLabel,
        jumpToPageDropdownLabel: this.texts.PrimeNgAria.jumpToPageDropdownLabel,
        jumpToPageInputLabel: this.texts.PrimeNgAria.jumpToPageInputLabel,
        selectRow: this.texts.PrimeNgAria.selectRow,
        unselectRow: this.texts.PrimeNgAria.unselectRow,
        expandRow: this.texts.PrimeNgAria.expandRow,
        collapseRow: this.texts.PrimeNgAria.collapseRow,
        showFilterMenu: this.texts.PrimeNgAria.showFilterMenu,
        hideFilterMenu: this.texts.PrimeNgAria.hideFilterMenu,
        filterOperator: this.texts.PrimeNgAria.filterOperator,
        filterConstraint: this.texts.PrimeNgAria.filterConstraint,
        editRow: this.texts.PrimeNgAria.editRow,
        saveEdit: this.texts.PrimeNgAria.saveEdit,
        cancelEdit: this.texts.PrimeNgAria.cancelEdit,
        listView: this.texts.PrimeNgAria.listView,
        gridView: this.texts.PrimeNgAria.gridView,
        slide: this.texts.PrimeNgAria.slide,
        slideNumber: this.texts.PrimeNgAria.slideNumber,
        zoomImage: this.texts.PrimeNgAria.zoomImage,
        zoomIn: this.texts.PrimeNgAria.zoomIn,
        zoomOut: this.texts.PrimeNgAria.zoomOut,
        rotateRight: this.texts.PrimeNgAria.rotateRight,
        rotateLeft: this.texts.PrimeNgAria.rotateLeft,
        listLabel: this.texts.PrimeNgAria.listLabel,
        selectColor: this.texts.PrimeNgAria.selectColor,
        removeLabel: this.texts.PrimeNgAria.removeLabel,
        browseFiles: this.texts.PrimeNgAria.browseFiles,
        maximizeLabel: this.texts.PrimeNgAria.maximizeLabel,
      },
    });
  }

  getLanguage(): string {
    return this.language;
  }

  getIsUserPortalCommunityTileEnabled(): boolean {
    return this.isUserPortalCommunityTileEnabled;
  }

  setIsUserPortalCommunityTileEnabled(value: boolean): void {
    this.isUserPortalCommunityTileEnabled = value;
  }

  getIsPrivateGptEnabled(): boolean {
    return this.isPrivateGptEnabled;
  }

  setIsPrivateGptEnabled(value: boolean): void {
    this.isPrivateGptEnabled = value;
  }

  getIsResourcesEnabled(): boolean {
    return this.isResourcesEnabled;
  }

  setIsResourcesEnabled(value: boolean): void {
    this.isResourcesEnabled = value;
  }

  getIsMonitoringEnabled(): boolean {
    return this.isMonitoringEnabled;
  }

  setIsMonitoringEnabled(value: boolean): void {
    this.isMonitoringEnabled = value;
  }

  getIsDepartmentsDynamic(): boolean {
    return this.isDepartmentsDynamic;
  }

  setIsDepartmentsDynamic(value: boolean): void {
    this.isDepartmentsDynamic = value;
  }

  getIsCronJobsEnabled(): boolean {
    return this.isCronJobsEnabled;
  }

  setIsCronJobsEnabled(value: boolean): void {
    this.isCronJobsEnabled = value;
  }

  getIsAdminPortalCommunityTileEnabled(): boolean {
    return this.isAdminPortalCommunityTileEnabled;
  }

  setIsAdminPortalCommunityTileEnabled(value: boolean): void {
    this.isAdminPortalCommunityTileEnabled = value;
  }

  /*setTenant(tenant: string): void {
    this.currentTenant = tenant;
  }

  getTenant(): string {
    return this.currentTenant;
  }

  setTenantName(tenantName: string): void {
    this.currentTenantName = tenantName;
  }

  getTenantName(): string {
    return this.currentTenantName;
  }*/

  //User User Singular
  setUserPortalUserNameSingular(userNameSingular: string): void {
    this.userPortalUserNameSingular = userNameSingular;
  }

  getUserPortalUserNameSingular(): string {
    return this.userPortalUserNameSingular;
  }

  //User User Plural
  setUserPortalUserNamePlural(userNamePlural: string): void {
    this.userPortalUserNamePlural = userNamePlural;
  }

  getUserPortalUserNamePlural(): string {
    return this.userPortalUserNamePlural;
  }

  //Admin User Singular
  setAdminPortalUserNameSingular(userNameSingular: string): void {
    this.adminPortalUserNameSingular = userNameSingular;
  }

  getAdminPortalUserNameSingular(): string {
    return this.adminPortalUserNameSingular;
  }

  //Admin User Plural

  setAdminPortalUserNamePlural(userNamePlural: string): void {
    this.adminPortalUserNamePlural = userNamePlural;
  }

  getAdminPortalUserNamePlural(): string {
    return this.adminPortalUserNamePlural;
  }

  setEmailSubjectLine(emailSubjectLine: string): void {
    this.emailSubjectLine = emailSubjectLine;
  }

  getEmailSubjectLine(): string {
    return this.emailSubjectLine;
  }

  setLoadUserGroups(loadUserGroups: boolean): void {
    this.loadUserGroups = loadUserGroups;
  }

  getLoadUserGroups(): boolean {
    return this.loadUserGroups;
  }

  // User Department Singular
  setUserPortalDepartmentNameSingular(departmentName: string): void {
    this.userPortalDepartmentNameSingular = departmentName;
  }

  getUserPortalDepartmentNameSingular(): string {
    return this.userPortalDepartmentNameSingular;
  }

  // User Department Plural
  setUserPortalDepartmentNamePlural(departmentNamePlural: string): void {
    this.userPortalDepartmentNamePlural = departmentNamePlural;
  }

  getUserPortalDepartmentNamePlural(): string {
    return this.userPortalDepartmentNamePlural;
  }

  // Admin Department Singular
  setAdminPortalDepartmentNameSingular(departmentName: string): void {
    this.adminPortalDepartmentNameSingular = departmentName;
  }

  getAdminPortalDepartmentNameSingular(): string {
    return this.adminPortalDepartmentNameSingular;
  }

  // Admin Department Plural
  setAdminPortalDepartmentNamePlural(departmentNamePlural: string): void {
    this.adminPortalDepartmentNamePlural = departmentNamePlural;
  }

  getAdminPortalDepartmentNamePlural(): string {
    return this.adminPortalDepartmentNamePlural;
  }

  getUserInviteEmailContent(): string {
    return this.userInviteEmailContent;
  }

  setUserInviteEmailContent(userInviteEmailContent: string): void {
    this.userInviteEmailContent = userInviteEmailContent;
  }

  getAdminInviteEmailContent(): string {
    return this.adminInviteEmailContent;
  }

  setAdminInviteEmailContent(adminInviteEmailContent: string): void {
    this.adminInviteEmailContent = adminInviteEmailContent;
  }

  setLearningCardConfigs(dashboardConfigs: DashBoardCardConfigDTO[]): void {
    this.learningCardConfigs = dashboardConfigs;
  }

  getLearningCardConfigs(): DashBoardCardConfigDTO[] {
    return this.learningCardConfigs;
  }

  setExternCardConfigs(dashboardConfigs: DashBoardCardConfigDTO[]): void {
    this.externCardConfigs = dashboardConfigs;
  }

  getExternCardConfigs(): DashBoardCardConfigDTO[] {
    return this.externCardConfigs;
  }

  setFavoriteCardConfigs(dashboardConfigs: DashBoardCardConfigDTO[]): void {
    this.favoriteCardConfigs = dashboardConfigs;
  }

  getFavoriteCardConfigs(): DashBoardCardConfigDTO[] {
    return this.favoriteCardConfigs;
  }

  getIsCustomRoleEnabled(): boolean {
    return this.isCustomRoleEnabled;
  }

  setIsCustomRoleEnabled(setValue: boolean): boolean {
    return (this.isCustomRoleEnabled = setValue);
  }

  getLicenseKey(): string {
    return this.licenseKey;
  }

  setLicenseKey(licenseKey: string): void {
    this.licenseKey = licenseKey;
  }

  getLicenseEdition(): string {
    return this.licenseEdition;
  }

  setLicenseEdition(licenseEdition: string): void {
    this.licenseEdition = licenseEdition;
  }

  getIsUserInviteEmailNotificationEnabled(): boolean {
    return this.isUserInviteEmailNotificationEnabled;
  }

  setIsUserInviteEmailNotificationEnabled(value: boolean): void {
    this.isUserInviteEmailNotificationEnabled = value;
  }

  getIsAdminInviteEmailNotificationEnabled(): boolean {
    return this.isAdminInviteEmailNotificationEnabled;
  }

  setIsAdminInviteEmailNotificationEnabled(value: boolean): void {
    this.isAdminInviteEmailNotificationEnabled = value;
  }

  getIsUserPortalEnabled(): boolean {
    return this.isUserPortalEnabled;
  }

  setIsUserPortalEnabled(value: boolean): void {
    this.isUserPortalEnabled = value;
  }

  getIsLanguageSwitchEnabled(): Observable<boolean> {
    return this.isLanguageSwitchEnabled.asObservable();
  }

  getCurrentTenantNameObs(): Observable<string> {
    return this.currentTenantName.asObservable();
  }

  getCurrentTenantName(): string {
    return this.currentTenantName.getValue();
  }

  setCurrentTenantName(tenantName: string): void {
    this.currentTenantName.next(tenantName);
  }

  getLightThemeObs(): Observable<string> {
    return this.lightTheme.asObservable();
  }

  getLightTheme(): string {
    return this.lightTheme.getValue();
  }

  setLightTheme(theme: string): void {
    this.lightTheme.next(theme);
  }

  getDarkThemeObs(): Observable<string> {
    return this.darkTheme.asObservable();
  }

  getDarkTheme(): string {
    return this.darkTheme.getValue();
  }

  setDarkTheme(theme: string): void {
    this.darkTheme.next(theme);
  }

  getIsLightThemeObs(): Observable<boolean> {
    return this.isLightTheme.asObservable();
  }

  getIsLightTheme(): boolean {
    return this.isLightTheme.getValue();
  }

  setIsLightTheme(isLightTheme: boolean): void {
    this.isLightTheme.next(isLightTheme);
  }

  setConfigUpdate(configUpdate: updateConfigRequestDTO): void {
    this.userPortalDepartmentNameSingular = configUpdate.userPortalDepartmentNameSingular;
    this.userPortalDepartmentNamePlural = configUpdate.userPortalDepartmentNamePlural;
    this.userPortalUserNameSingular = configUpdate.userPortalUserNameSingular;
    this.userPortalUserNamePlural = configUpdate.userPortalUserNamePlural;
    this.adminPortalDepartmentNameSingular = configUpdate.adminPortalDepartmentNameSingular;
    this.adminPortalDepartmentNamePlural = configUpdate.adminPortalDepartmentNamePlural;
    this.adminPortalUserNameSingular = configUpdate.adminPortalUserNameSingular;
    this.adminPortalUserNamePlural = configUpdate.adminPortalUserNamePlural;
    this.emailSubjectLine = configUpdate.emailSubjectLine;
    this.externCardConfigs = configUpdate.externCardsConfigs;
    this.learningCardConfigs = configUpdate.learningMaterialCardsConfigs;
    this.favoriteCardConfigs = configUpdate.favoriteCardsConfigs;
    this.lightTheme.next(configUpdate.lightTheme);
    this.darkTheme.next(configUpdate.darkTheme);
  }
}
