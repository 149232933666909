<p-toast key="centraltoast" />

<ng-container *ngIf="isTenantSwitched && !isOnboarding && !isConsent">
  <app-configuration></app-configuration>
</ng-container>

<ng-container *ngIf="isOnboarding && isTenantSwitched">
  <router-outlet></router-outlet>
</ng-container>

<ng-container *ngIf="isConsent">
  <router-outlet></router-outlet>
</ng-container>

<ng-container *ngIf="!isTenantSwitched">
  <app-tenant-selection
    [connectedTenants]="connectedTenants"
    [selectedTenantId]="selectedTenantId"
    (tenantSelected)="selectTenant($event)"
  ></app-tenant-selection>
</ng-container>
