export class GetNameOfEmail {
  public static getNameOfEmail(userEmail: string): string {
    var firstName = '';
    var lastName = '';
    var currentChar = userEmail.charAt(0);
    for (var i = 0; i < userEmail.length; i++) {
      currentChar = userEmail.charAt(i);
      if (currentChar == '.') {
        firstName = userEmail.substring(0, i);
        for (var j = i; j < userEmail.length; j++) {
          currentChar = userEmail.charAt(j);
          if (currentChar == '@') {
            lastName = userEmail.substring(i + 1, j);
          }
        }
        j = userEmail.length;
        i = j;
      }
    }

    firstName = firstName.charAt(0).toUpperCase() + firstName.substring(1, firstName.length);
    lastName = lastName.charAt(0).toUpperCase() + lastName.substring(1, lastName.length);
    var name = firstName + ' ' + lastName;

    return name;
  }
}
