<div style="margin-top: 10%">
  <p-avatar (click)="userMenu.toggle($event)" size="large" shape="circle" class="pointer">
    <img *ngIf="this.avatar" [src]="pic" />
    <img *ngIf="!this.avatar" [src]="getAvatarUrl()" />
  </p-avatar>
</div>
<p-overlayPanel #userMenu>
  <div style="display: flex; flex-direction: column">
    <span style="margin: auto">{{ username }}</span>
    <span style="margin: auto">{{ email }}</span>
  </div>
  <!--   <p-divider styleClass="my-2" type="solid"></p-divider>
  <div class="card flex justify-content-center">
    <div style="display: flex; align-items: center; justify-content: space-evenly">
      <p-button
        (onClick)="onSwitchChange()"
        label="Switch Dashboard"
        icon="pi
      pi-arrow-right-arrow-left"
      />
    </div> -->

  <p-divider styleClass="my-2" type="solid"></p-divider>

  <div *ngIf="isLanguageSwitchEnabled">
    <div
      class="card"
      style="display: flex; text-align: center; justify-content: center; align-content: center; align-items: center"
    >
      <div style="text-align: center; margin-right: 4px">DE</div>
      <p-inputSwitch [(ngModel)]="isLanguageSelectionChecked" (onChange)="onLanguageSwitchChange($event)" />
      <div style="text-align: center; margin-left: 4px">EN</div>
    </div>
  </div>

  <div>
    <div
      class="card"
      style="display: flex; text-align: center; justify-content: center; align-content: center; align-items: center"
    >
      <div style="text-align: center; margin-right: 4px">Dark</div>
      <p-inputSwitch [(ngModel)]="isLightTheme" (onChange)="onThemeChange($event)" />
      <div style="text-align: center; margin-left: 4px">Light</div>
    </div>
  </div>

  <div class="sign-button-wrapper">
    <p-button
      *ngIf="dataService?.getTexts()"
      label="{{ dataService.getTexts().UserMenuLogoutButtonText }}"
      (click)="signOut()"
      text="true"
      icon="pi pi-sign-out"
    />
  </div>
</p-overlayPanel>
