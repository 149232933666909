import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IsInstanceAdminService {
  // Initialisiere BehaviorSubject mit einem Default-Wert (false)
  private isInstanceAdminSubject = new BehaviorSubject<boolean>(null);

  // Exponiere das Observable für Abonnements
  public isInstanceAdmin$: Observable<boolean> = this.isInstanceAdminSubject.asObservable();

  constructor() {}

  // Methode zum Setzen des Admin-Status
  public setIsInstanceAdmin(isInstanceAdmin: boolean): void {
    this.isInstanceAdminSubject.next(isInstanceAdmin);
  }

  // Methode zum Abfragen des aktuellen Admin-Status
  public getCurrentIsInstanceAdmin(): boolean {
    return this.isInstanceAdminSubject.getValue();
  }
}
