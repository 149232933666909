<div
  style="
    min-width: 300px;
    height: 100vh;
    background-image: url('../assets/cube_mono.jpg');
    background-size: cover;
    background-position: center;
  "
>
  <div class="flex justify-content-center align-items-center h-screen">
    <div class="card" style="width: auto">
      <div class="card-header">
        <h2>Welcome to Cloud Control</h2>
        <p>Please select the organization you would like to use.</p>
      </div>
      <p-divider></p-divider>
      <div *ngIf="isLoading" style="display: flex; flex-direction: column; align-items: center; margin-top: 5%">
        <p-progressSpinner styleClass="w-4rem h-4rem " strokeWidth="2" animationDuration="1s"></p-progressSpinner>
        <div>
          <p class="shimmer">Please wait while we are processing your request!</p>
        </div>
      </div>
      <div>
        <div *ngIf="!isLoading">
          <div *ngFor="let tenant of connectedTenants" class="selection">
            <div class="tenant-option">
              <label for="{{ tenant.tenantId }}" class="tenant-label">
                <i class="pi pi-building" style="font-size: 1.5em"></i>
                {{ tenant.displayName }}
              </label>
              <p-radioButton
                class="tenant-radio"
                name="tenant"
                [value]="tenant.tenantId"
                [(ngModel)]="selectedTenantId"
                inputId="{{ tenant.tenantId }}"
                (onClick)="selectTenant(tenant.tenantId)"
              ></p-radioButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
