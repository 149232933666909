<p-menubar [model]="items">
  <ng-template pTemplate="start">
    <img
      *ngIf="isSkoolDashboard"
      src="../../../../assets/skool_logo.png"
      alt="logo"
      style="width: 4rem; margin-right: 0.5rem; cursor: pointer"
      (click)="onHomeClick()"
    />
    <img
      *ngIf="!isSkoolDashboard"
      src="../../../../assets/logo.svg"
      alt="logo"
      style="width: 4rem; margin-right: 0.5rem; cursor: pointer"
      (click)="onHomeClick()"
    />
  </ng-template>

  <ng-template pTemplate="end">
    <app-usermenu></app-usermenu>
  </ng-template>
</p-menubar>

<router-outlet></router-outlet>
