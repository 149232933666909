import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-tenant-consent',
  templateUrl: './tenant-consent.component.html',
  styleUrls: ['./tenant-consent.component.scss'],
})
export class TenantConsentComponent implements OnInit {
  tenantName: string;

  constructor() {
    this.close();
  }

  ngOnInit(): void {
    this.close();
  }

  close() {
    window.close();
  }
}
