<div style="height: fit-content; overflow-x: hidden; width: 100%">
  <div *ngIf="data.users">
    <div *ngFor="let user of data.users" class="flex-container-space-between-auto">
      <div
        style="
          display: flex;
          flex-direction: row;
          align-items: center;
          background-color: var(--surface-50);
          border-radius: 5px;
          gap: 2%;
          margin-bottom: 4px;
          padding-left: 12px;
          width: auto;
        "
      >
        <p-avatar shape="circle">{{ getFirstLetterFromName(user.name) }}</p-avatar>
        <div>
          <p class="no-margin-bold">{{ user.name }}</p>
          <p class="no-margin-gray">{{ user.email }}</p>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="data.departments">
    <div *ngFor="let department of data.departments" class="flex-container-space-between-auto">
      <div
        style="
          display: flex;
          flex-direction: row;
          align-items: center;
          background-color: rgba(243, 243, 243, 0.5294117647);
          border-radius: 5px;
          gap: 2%;
          margin-bottom: 4px;
          padding-left: 12px;
          width: auto;
        "
      >
        <p-avatar shape="circle">{{ getFirstLetterFromName(department.name) }}</p-avatar>
        <div>
          <p class="no-margin-bold">{{ department.name }}</p>
          <p class="no-margin-gray">{{ department.description }}</p>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="data.roles">
    <div *ngFor="let role of data.roles" class="flex-container-space-between-auto">
      <div
        style="
          display: flex;
          flex-direction: row;
          align-items: center;
          background-color: rgba(243, 243, 243, 0.5294117647);
          border-radius: 5px;
          gap: 2%;
          margin-bottom: 4px;
          padding-left: 12px;
          width: auto;
        "
      >
        <p-avatar shape="circle">{{ getFirstLetterFromName(role.name) }}</p-avatar>
        <div>
          <p class="no-margin-bold">{{ role.name }}</p>
          <p class="no-margin-gray">{{ role.description }}</p>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="data.roles || data.users || data.departments">
    <p-divider></p-divider>
  </div>

  <div style="height: fit-content; min-height: 32px; display: flex">
    <p-button
      (click)="onNoClick()"
      [text]="true"
      icon="pi pi-times"
      label="{{ this.dataService.getTexts().ButtonTextCancel }}"
    />
    <p-button
      (click)="delete()"
      icon="pi pi-trash"
      label="{{ data.isDelete ? dataService.getTexts().ButtonTextDelete : dataService.getTexts().ButtonTextUpdate }}"
    />
  </div>
</div>
