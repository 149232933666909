export class Validator {
  public static validiateSecret(secret: string): boolean {
    if (secret.charAt(5) === '~') {
      if (secret.length === 40) {
        var copy = secret.toLocaleLowerCase();
        for (let i = 0; i < secret.length; i++) {
          let c = copy.charAt(i).charCodeAt(0);
          //a-z
          if (c < 97 || c > 122) {
            //0-9
            if (c < 48 || c > 57) {
              //~ . _ -
              if (c != 126 && c != 95 && c != 45 && c != 46) {
                return false;
              }
            }
          }
        }

        return true;
      }
    }

    return false;
  }

  public static validiateId(id: string): boolean {
    if (id.length == 36) {
      if (
        id.charAt(8) === '-' &&
        id.charAt(8) === id.charAt(13) &&
        id.charAt(18) === id.charAt(13) &&
        id.charAt(18) === id.charAt(23)
      ) {
        var copy = id.toLocaleLowerCase();
        for (let i = 0; i < id.length; i++) {
          let c = copy.charAt(i).charCodeAt(0);

          if (c < 97 || c > 122) {
            //0-9
            if (c < 48 || c > 57) {
              //-
              if (c != 45) {
                return false;
              }
            }
          }
        }

        return true;
      }
    }
    return false;
  }

  public static validateEmail(email: string): boolean {
    if (email.length < 4) return false;

    return new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    ).test(email);
  }
}
